<template>
  <v-container fluid class="blue">
    <v-row align="center" justify="center" style="height: 100vh">
      <v-card class="pa-8 ma-5" width="380">
        <v-card-title class="d-flex justify-center" v-if="!showMessage">
          sweeep
        </v-card-title>
        <div v-if="showMessage">
          <p>{{ $t('message.reset_password_success') }}</p>
          <router-link to="/session/login">{{
            $t('message.reset_password_click_login')
          }}</router-link>
        </div>
        <div v-if="!showMessage">
          <v-form ref="form" v-model="valid" class="mb-4">
            <v-text-field
              :label="newPassword_label"
              v-model="newPassword"
              :type="show_password ? 'text' : 'password'"
              :rules="passwordRules"
              validate-on-blur
              :hint="$t('message.password_validation')"
              prepend-inner-icon="feather-lock"
              :append-icon="show_password ? 'feather-eye-off' : 'feather-eye'"
              @click:append="show_password = !show_password"
              class="mb-3"
              required
            ></v-text-field>
            <v-text-field
              :label="repeatNewPassword_label"
              v-model="confirmPassword"
              :type="show_password ? 'text' : 'password'"
              :rules="confirmPasswordRules"
              validate-on-blur
              :hint="$t('message.password_validation')"
              prepend-inner-icon="feather-lock"
              :append-icon="show_password ? 'feather-eye-off' : 'feather-eye'"
              @click:append="show_password = !show_password"
              @keyup.enter="submit"
              class="mb-3"
              required
            ></v-text-field>
            <v-btn @click="submit" block color="blue" dark>{{
              $t('message.resetPassword')
            }}</v-btn>
          </v-form>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import Vue from 'vue'
import Snackbar from 'Helpers/snackbar/index'

export default {
  components: {
    SessionSliderWidget,
  },
  data() {
    var self = this
    return {
      valid: false,
      newPassword: '',
      confirmPassword: '',
      newPassword_label: '',
      show_password: false,
      repeatNewPassword_label: '',
      passwordRules: [
        (v) => !!v || self.$t('message.form_input_required'),
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          self.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) ||
          '50' + self.$t('message.character_max_length'),
      ],
      confirmPasswordRules: [
        (v) => !!v || self.$t('message.form_input_required'),
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          self.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) ||
          '50' + self.$t('message.character_max_length'),
        (v) =>
          (v && v == self.newPassword) ||
          self.$t('message.error_password_and_confirm_not_same'),
      ],
      appLogo: AppConfig.appLogo2,
      showMessage: false,
    }
  },
  mounted() {
    try {
      this.newPassword_label = this.$t('message.reset_password_newpassword')
      this.repeatNewPassword_label = this.$t(
        'message.reset_passwrod_repeatnewPassword'
      )
    } catch {}
  },
  methods: {
    isIncludeUpper(text) {
      for (let s of text) {
        if (s.match(/^[A-Z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeLower(text) {
      for (let s of text) {
        if (s.match(/^[a-z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeNumber(text) {
      for (let s of text) {
        if (!isNaN(s)) {
          return true
        }
      }
      return false
    },
    isIncludeSymbol(text) {
      let reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g)
      if (reg.test(text)) {
        return true
      }
      return false
    },
    submit() {
      try {
        if (this.$refs.form.validate()) {
          if (this.newPassword == this.confirmPassword) {
            let apiUrl = this.$store.getters.apiForgotPasswordFunction
            let data = {
              id: this.$route.params.id,
              reset_code: this.$route.params.code,
              password: this.newPassword,
            }
            this.$store
              .dispatch('apiResetPassword', {
                data,
                apiUrl,
              })
              .then((response) => {
                if (response.data.status == 'success') {
                  this.showMessage = true
                } else {
                  Snackbar.error(response.data.error)
                }
              })
          } else {
            Snackbar.error('パスワードが違います')
          }
        }
      } catch {}
    },
  },
}
</script>
